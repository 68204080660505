exports.components = {
  "component---src-pages-2019-swing-of-time-js": () => import("./../../../src/pages/2019-swing-of-time.js" /* webpackChunkName: "component---src-pages-2019-swing-of-time-js" */),
  "component---src-pages-2021-venla-taavitsainen-js": () => import("./../../../src/pages/2021-venla-taavitsainen.js" /* webpackChunkName: "component---src-pages-2021-venla-taavitsainen-js" */),
  "component---src-pages-2022-jano-js": () => import("./../../../src/pages/2022-jano.js" /* webpackChunkName: "component---src-pages-2022-jano-js" */),
  "component---src-pages-2023-hokkus-js": () => import("./../../../src/pages/2023-hokkus.js" /* webpackChunkName: "component---src-pages-2023-hokkus-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-yhdistys-tarkasta-jasenyys-js": () => import("./../../../src/pages/yhdistys/tarkasta-jasenyys.js" /* webpackChunkName: "component---src-pages-yhdistys-tarkasta-jasenyys-js" */),
  "component---src-templates-news-index-js": () => import("./../../../src/templates/news-index.js" /* webpackChunkName: "component---src-templates-news-index-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */)
}

